import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil } from 'lodash';
import { IMyDpOptions } from 'mydatepicker';
import { Observable } from 'rxjs';
import * as dealerActions from '../../../actions/dealer-detail.action';
import * as fromRoot from '../../../reducers';
import { SharedDataService } from '../../../services/common/shared-data.service';
import * as viewModels from '../../../services/view-model/view-models';
import { ReportData } from '../../master-data/reportData';


@Component({
  selector: 'region-dashboard-search-toolbar',
  templateUrl: 'region-dashboard-search-toolbar.component.html',
  styleUrls: ['./region-dashboard-search-toolbar.component.scss']
})

export class RegionDashboardSearchToolbarComponent {

  @Output() viewInventoryEvent = new EventEmitter<any>();

  @Output() findDealerEvent = new EventEmitter<any>();

  @Output() findVehicleEvent = new EventEmitter<any>();

  @Input() enteredDealerCode: string;

  @Output() filterDateEvent = new EventEmitter<any>();

  @Input() enteredvin: string;

  @Input() fromDate: string;

  @Input() toDate: string;

  @Input() labelText: string = 'Certification Date';

  @Input() regionSelection: string;

  @Input() districtSelection: string;

  monthList: Array<any> = [];
  yearList: Array<any> = [];
  selectedOpt = 'm';

  @Input() selectedMonth: string;
  @Input() selectedYear: string;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false
  };

  filterMethoditems: Array<any> =
    [
      { name: 'Show All', value: 'Show All', disabled: false },
      { name: 'Current Week', value: 'Current Week', disabled: false },
      { name: 'Current Month', value: 'Current Month', disabled: false },
      { name: 'Current Date', value: 'Current Date', disabled: true }
    ];

  @Input() isDateSelected: boolean = false;
  dealerDetailsData$: Observable<viewModels.DealerDetailView>;
  regionItems: Array<any>;
  districtItems: Array<any>;

  constructor(private store: Store<fromRoot.AppState>,
    public sharedDataService: SharedDataService) {

    this.onDisableFuture();
    if (isNil(this.sharedDataService.dealerData)) {
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    }

    if (isNil(this.sharedDataService.dealerData)) {
      this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
      this.dealerDetailsData$.subscribe(data => {
        if (!isNil(data) && data !== undefined) {
          if (!isNil(data.allDealers) && data.allDealers.length > 0) {
            this.sharedDataService.dealerData = cloneDeep(data.allDealers);
            let regionArrayCopy2 = new Array<any>();
            regionArrayCopy2.push({ name: 'ALL' });

            if (this.sharedDataService.dealerData !== undefined) {
              this.sharedDataService.dealerData.forEach(element => {
                if (element.brand === this.sharedDataService.brandName &&
                  element.areaRegionNo !== '83') {
                  let dataReg = {
                    name: element.areaRegionName,
                    makeCode: element.brand
                  };
                  regionArrayCopy2.push(dataReg);
                }
              });
            }

            regionArrayCopy2 = this.sort_by_key(regionArrayCopy2, 'name');
            this.regionItems = regionArrayCopy2.map(item => item.name)
              .filter((item, i, ar) => ar.indexOf(item) === i);
            console.log(this.regionItems);

          }
        }
      });
    }
    let regionArrayCopy = new Array<any>();
    regionArrayCopy.push({ name: 'ALL' });
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName && element.areaRegionNo !== '83') {
          let data = {
            name: element.areaRegionName,
            makeCode: element.brand
          };
          regionArrayCopy.push(data);
        }
      });
    }
    regionArrayCopy = this.sort_by_key(regionArrayCopy, 'name');
    this.regionItems =
      regionArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);
    let districtArrayCopy = new Array<any>();
    districtArrayCopy.push({ name: 'ALL' });
    /* if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          let dataDist = {
            name: element.districtNo,
            makeCode: element.brand
          };
          districtArrayCopy.push(dataDist);
        }
      });
    } */
    districtArrayCopy = this.sort_by_key(districtArrayCopy, 'name');
    this.districtItems =
      districtArrayCopy.map(item => item.name)
        .filter((item, i, ar) => ar.indexOf(item) === i);
    let date = new Date();
    this.monthList = ReportData.monthDetails;
    for (let i = date.getFullYear() - 10; i <= date.getFullYear(); i++) {
      this.yearList.push(i.toString());
    }
  }

  selection(event: any) {
    this.selectedOpt = event.value;
  }

  getDistricts(event: any) {
    console.log('55OnChange');
    console.log(event.value);

    let districtArrayCopy = new Array<any>();
    districtArrayCopy.push({ name: 'ALL' });
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          if (element.areaRegionName === event.value) {
            let data = {
              name: element.districtNo,
              makeCode: element.brand
            };
            districtArrayCopy.push(data);
          }
        }
      });
    }
    districtArrayCopy = this.sort_by_key(districtArrayCopy, 'name');
    this.districtItems =
      districtArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);
    this.districtSelection = 'ALL';

  }

  sort_by_key(array, key) {
    return array.sort(function (a, b) {
      if (b[key] === 'ALL') return 1;
      let x = a[key]; let y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  viewInventory() {
    this.viewInventoryEvent.emit(this.enteredDealerCode);
  }

  findDealer() {
    this.findDealerEvent.emit();
  }

  findVehicle() {
    this.enteredvin = this.enteredvin.toUpperCase().trim();
    this.findVehicleEvent.emit(this.enteredvin);
  }

  filterValueChanged() {
    let dateValues = {
      filter: 'between',
      fromDate: this.fromDate,
      toDate: this.toDate,
      region: this.regionSelection,
      district: this.districtSelection,
      month: this.selectedMonth,
      year: this.selectedYear,
      reportType: this.selectedOpt
    };

    this.filterDateEvent.emit(dateValues);

  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  onDisableFuture() {
    let date = new Date();
    // Disable/enable dates from 5th forward
    date.setMonth(date.getMonth() + 1);
    let copy = this.getCopyOfOptions();
    copy.disableSince = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate() + 1
    };
    this.myDatePickerOptions = copy;
  }

  dateFilter(event: any, value: any) {

    if (value === 'fromDate') {
      this.fromDate = event.formatted;
    }

    if (value === 'toDate') {
      this.toDate = event.formatted;
    }

    if (value === 'regionSelection') {
      this.regionSelection = event.region;
    }

    if (value === 'districtSelection') {
      this.districtSelection = event.district;
    }
    if (value === 'selectedMonth') {
      this.selectedMonth = event.month;
    }

    if (value === 'selectedYear') {
      this.selectedYear = event.year;
    }
    console.log('dateFilter method from rdr-report-search-toolbar');
    console.log(this.fromDate);
    console.log(this.regionSelection);
    console.log(this.districtSelection);

    if (this.fromDate !== undefined && this.toDate !== undefined) {
      this.isDateSelected = true;
    }

  }
}
